const cardData = [
  {
    id: 1,
    title: 'about me',
    description:
      "Throw her in the brig. Bite my shiny metal ass. You don't know how to do any of those. I haven't felt much of anything since my guinea pig died. I found what I need. And it's not friends, it's things.",
    src: 'City.jpg',
  },
  {
    id: 2,
    title: 'resume',
    description:
      'Why, those are the Grunka-Lunkas! They work here in the Slurm factory. Ven ve voke up, ve had zese wodies. The key to victory is discipline, and that means a well made bed. You will practice until you can make your bed in your sleep.',
    src: 'Paper.jpg',
  },
  {
    id: 3,
    title: 'projects',
    description:
      "Michelle, I don't regret this, but I both rue and lament it. Ow, my spirit! Check it out, y'all. Everyone who was invited is here. Is today's hectic lifestyle making you tense and impatient? Bender, quit destroying the universe!",
    src: 'Project.jpg',
  },
  // {
  //   title: 'Contact Me',
  //   description:
  //     "Bender, quit destroying the universe! Tell them I hate them. Who am I making this out to? No, of course not. It was… uh… porno. Yeah, that's it.",
  // },
];

export default cardData;
